import ReactDOM from "react-dom";
import "./index.css";
import * as serviceWorker from "./serviceWorker";
import "./locals/i18n";
import React from "react";
import { BrowserRouter } from "react-router-dom";

const render = () => {
    const App = require("./app/App").default;

    ReactDOM.render(
        <React.StrictMode>
            <BrowserRouter>
                <App />
            </BrowserRouter>
        </React.StrictMode>,
        document.getElementById("root")
    );
};

render();

// if (process.env.NODE_ENV === "development" && module.hot) {
//     module.hot.accept("./app/App", render);
// }

// ReactDOM.render(<App />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
