
import { useTranslation } from "react-i18next";
import { Container } from '@mantine/core';


/**
 * The Speakup-page informs you about the speakup project
 */
export const Agchancengleichheit = () => {
  const { t } = useTranslation();

    return (
        <Container>
            <h2>{t("AGequal.title")}</h2>
            <p>{t("AGequal.beschreibung")}</p>
        </Container>
    );
};
