import { createStyles } from '@mantine/core';

export const useStyles = createStyles((theme) => ({
    cardBody: {
        display: "grid"
    },
    cardTitle: {
        textAlign: "center",
        padding: "1rem"
    },
    cardText: {
        padding: "1rem"
    },
    cardFooter: {
        textAlign: "center",
    },
    button: {
        margin: "1rem"
    },
    inverseCard: {
        backgroundColor: theme.colors.vsethMain[7],
        color: '#fff',
        display: "grid"
    }
}));