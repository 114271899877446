import { ReactNode } from "react";
import "./App.css";
import { Link, Route, Routes, useLocation } from "react-router-dom";
import { MantineProvider } from "@mantine/core";
import { HomePage } from "../pages/HomePage";
import { Speakup } from "../pages/speakup";
import { Agchancengleichheit } from "../pages/agchancengleichheit";
import { Helpdesk } from "../pages/helpdesk2";
import { VSETHExternalApp, VSETHThemeProvider, makeVsethTheme, useConfig } from "vseth-canine-ui";
import { useTranslation } from "react-i18next";

/**
 * `App` is the main component of the application it set up the main structural components
 * such as the navigation bar and the Routes.
 */
const App: React.FC = () => {

    const {t, i18n} = useTranslation();
    const theme = makeVsethTheme();
    const sekMenuNav = [
        {
            title: t("Navbar.helpdesk"),
            href: "/helpdesk",
        },
        {
            title: t("Navbar.speakupnavbar"),
            href: "/speakup",
        },
        {
            title: t("Navbar.agchancengleichheit"),
            href: "/agchancengleichheit",
        },
    ]

    let {data, } = useConfig(
        "https://static.vseth.ethz.ch/assets/vseth-0000-vseth/config.json"
        );

    return (
        <>
            <MantineProvider theme={theme}>
                <VSETHThemeProvider theme={theme}>
                    <VSETHExternalApp
                        selectedLanguage={i18n.language}
                        onLanguageSelect={(lang) => i18n.changeLanguage(lang)}
                        languages={data?.languages}
                        organizationNav={data.externalNav}
                        title={t("Navbar.startseite")}

                        makeWrapper={(url: string | undefined, child: ReactNode) => (
                            <Link to={url!} style={{ textDecoration: "none", color: "inherit" }}>
                                {child}
                            </Link>
                        )}
                        privacyPolicy="https://account.vseth.ethz.ch/impressum"
                        activeHref={useLocation().pathname}
                        appNav={sekMenuNav}
                    >
                    <Routes>
                        <Route path="/" element={<HomePage/>} />
                        <Route path="/speakup" element={<Speakup/>} />
                        <Route path="/agchancengleichheit" element={<Agchancengleichheit/>} />
                        <Route path="/helpdesk" element={<Helpdesk/>} />
                    </Routes>
					</VSETHExternalApp>
				</VSETHThemeProvider>
			</MantineProvider>
        </>
    );
};

export default App;
