import { useTranslation } from "react-i18next";
import { Container, Card, Button, Space } from '@mantine/core';
import { useStyles } from "../components/styles";
import { Icon, ICONS } from "vseth-canine-ui";
/**
 * The Speakup-page informs you about the speakup project
 */
export const Speakup = () => {
  const { t } = useTranslation();
  const { classes } = useStyles();

    return (
        <Container>
            <Card className={classes.inverseCard}>
                <Card.Section className={classes.cardText}>
                   {t("Speakup.intro")}
                </Card.Section>
                <Card.Section className={classes.cardText}>
                    {t("Speakup.verlinkung")}
                </Card.Section>
                <Card.Section className={classes.cardFooter}>
                    <Button className={classes.button} color="dark" component="a" href="https://speakup.vseth.ethz.ch/" target="blank">Website</Button><br/>
                    <Button className={classes.button} color="dark" component="a" href="https://instagram.com/speakupeth/" target="_blank" ><Icon icon={ICONS.INSTAGRAM} size={15} color="white"/> <Space w="xs" />Instagram</Button>
                </Card.Section>  
            </Card>
        </Container>
    );
};
